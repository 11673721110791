<template>
    <div class="history">
        <client-filter-block :is-load-xml="condition.idLoadXml"
                              @download-xls="downloadXls()"
                              :is-load-data="condition.isLoadTable"
                              :clients="clients"
                              @init-clients-list="initList(1)"
                              @init-clients-fast-list="initList(1, true)"
                              ref="filter"></client-filter-block>
        <clients-list ref="clientList"
                     @init-clients-list="initList()"
                     @filter-status-wrap="filterStatusWrap()"
                     :count-prolongation="countProlongation"
                     :is-load-data="condition.isLoadTable"
                     :is-mobile="this.condition.isMobile"
                     :clients="clients"></clients-list>
        <div style="text-align: center">
            <pagination v-show="total > 0" :total="total"
                        :page.sync="listQuery.page"
                        :limit.sync="listQuery.limit" @pagination="initList()" />
        </div>
    </div>
</template>


<script>
    import Vue from "vue";
   // import {orderMixin} from '@/mixin'
    import Pagination from '@/components/Pagination';
    import { downloadExcel, getData} from "@/api/client";
    import { default as clientsList } from "@/views/clients/clientsList";
    import { default as ClientFilterBlock } from "@/views/clients/filterBlock";

    const FileDownload = require('js-file-download');

    export default {
      //  mixins: [orderMixin],
        components: {
            clientsList,
            Pagination,
            ClientFilterBlock,
            downloadExcel
        },
        data: function () {
            return {
                clients: [],
                total: 1,
                countProlongation: 0,
                mobileWith: 992,
                condition: {
                    isLoadTable: true,
                    isLoadXml: false,
                    isMobile: false,
                },
                listQuery: {
                    page: 1,
                    limit: 10,
                }
            }
        },
        created: function() {
            window.addEventListener("resize", this.checkWindowResize);
            this.checkIsMobile();
            this.tooltipActivate();
        },
        mounted: function() { // The hook. Here, Vue has already worked its magic.
            this.initList();
        },
        methods: {
            tooltipActivate(){
              $(document).ready(function(){
                $('[data-toggle="tooltip"]').tooltip();
              });
            },
            checkIsMobile: function() {
                return this.condition.isMobile = window.innerWidth < this.mobileWith;
            },
            checkWindowResize: function() {
                this.checkIsMobile()
            },
            filterStatusWrap: function() {
                this.$refs.filter.filterStatusWrap(true);
            },
            getQuery: function () {
                return {...this.$refs.filter.listQuery, ...this.$refs.clientList.listQuery, ...this.listQuery};
            },
            getFastQuery: function () {
                return {"fastSearch" : this.$refs.filter.fastSearch};
            },
            downloadXls : async function() {
                Vue.set(this.condition, 'idLoadXml', true);

                let response = await downloadExcel({...this.$refs.filter.listQuery, ...this.$refs.clientList.listQuery});

                if (response?.data) {
                    FileDownload(response.data, 'report.xlsx');
                }

                Vue.set(this.condition, 'idLoadXml', false);
            },
            initList: async function (filterButtonEvent, fastSearch = false) {
                if (filterButtonEvent === 1) {
                    this.listQuery.page = 1;
                }

                let response;
                this.clients = [];
                this.condition.isLoadTable = true;
                if(fastSearch){
                  response = await getData(this.getFastQuery())
                }else{
                  response = await getData(this.getQuery())
                }
              if(response){
                const { data } = response;
                this.countProlongation = data.data?.countProlongation ?? data.data?.total;
                this.clients = data.data.items;
                this.total = data.data?.total;
              }
              this.condition.isLoadTable = false;
            },
        },
        destroyed() {
            window.removeEventListener("resize", this.checkWindowResize);
        },
    }
</script>
